import IMAGES from './images'

const resumes = [
  {
    title: 'Google',
    subtitle: '2018, No prior job experience',
    images: {
      avatar: IMAGES.google.avatar,
      img: IMAGES.v4.img,
    },
    link: 'https://bit.ly/PreGoogleResume',
  },
  {
    title: 'Facebook',
    subtitle: '2019 - present',
    images: {
      avatar: IMAGES.facebook.avatar,
      img: IMAGES.v5.img,
    },
    link:
      'https://drive.google.com/file/d/14tkNShzZhYU2r8hdLf2MI7SzhMa-uwq7/view?usp=sharing',
  },
  {
    title: 'Harvard Business School',
    subtitle: 'Entering 2022, No prior business experience',
    images: {
      avatar: IMAGES.harvard.avatar,
      img: IMAGES.v6.img,
    },
    link:
      'https://drive.google.com/file/d/1dT_Mua2Y-PHFvHiAeaUWmqSwtVX937TE/view?usp=sharing',
  },
]

export default resumes
