import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Divider, Grid, GridList, Typography } from '@material-ui/core'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'

import { FONTS, img_embed, URLs } from '../utils/core'
import Card from '../components/card_w_header'
import Colors from '../utils/colors'
import CustomLink from '../components/link'
import Icon from '../components/icon'
import IMAGES from '../data/images'
import Logger from '../utils/logger'
import Page from './page'
import resumes from '../data/resumes'
import Window from '../utils/window'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  main: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    margin: 20,
  },
  section: {
    fontFamily: FONTS.primary,
    fontSize: 'x-large',
    textAlign: 'center',
    margin: 10,
  },
  content: {
    justifyContent: 'space-evenly',
  },
  preview: {
    minWidth: 350,
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: 500,
  },
  details: {
    minWidth: '50%',
    width: 'initial !important',
    '& p': {
      fontFamily: FONTS.primary,
      fontWeight: 300,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '15px !important',
  },
  button: {
    margin: 5,
    padding: '10px !important',
    width: 'initial !important',
  },

  divider: {
    margin: 10,
  },
  cta: {
    fontFamily: FONTS.primary,
    fontWeight: 300,
    textAlign: 'center',
    '& div': {
      margin: 10,
    },
  },

  resumes: {
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    justifyContent: 'center',
  },
  card: {
    flex: 0,
  },
}))

function Resumes() {
  const classes = useStyles()

  function content() {
    return (
      <Grid container className={classes.container}>
        {/** MAIN CONTENT */}
        <Grid className={classes.main}>
          <Grid item>
            <Typography className={classes.section}>
              My Resume Template
            </Typography>
          </Grid>
          <GridList cellHeight={'auto'} cols={2} className={classes.content}>
            <Grid item className={classes.preview}>
              <img
                src={img_embed(IMAGES.latest.img)}
                alt="Preview of resume"
                className={classes.previewImage}
              />
            </Grid>
            <Grid item className={classes.details}>
              <p>
                I spent a lot of time crafting my own resume template to stand
                out to recruiters.
              </p>
              <p>
                As I've mentioned in my YouTube videos, I got into Google with{' '}
                <u>no job experience</u> and Harvard Business School with{' '}
                <u>no business experience.</u>
              </p>
              <p>
                To make the most of this template, you'll need to download the{' '}
                <CustomLink
                  text="Open Sans"
                  event="download_font"
                  url="https://fonts.google.com/specimen/Open+Sans"
                />{' '}
                and{' '}
                <CustomLink
                  text="Montserrat"
                  event="download_font"
                  url="https://fonts.google.com/specimen/Montserrat"
                />{' '}
                fonts.
              </p>

              <GridList cellHeight={50} cols={3} className={classes.buttons}>
                <Button
                  startIcon={<Icon src="download" color="white" />}
                  style={{
                    backgroundColor: Colors.get('blue'),
                    color: Colors.get('white'),
                  }}
                  className={classes.button}
                  onClick={() =>
                    Logger.log(
                      'download_template',
                      'https://drive.google.com/uc?export=download&id=1BmBv-BbQE5pRPSQ0TpFDTCJgOfoEh7hL'
                    )
                  }
                >
                  Direct Download Template
                </Button>
                <Button
                  startIcon={<Icon src="review" color="blue" />}
                  style={{
                    color: Colors.get('blue'),
                    border: `1px solid ${Colors.get('blue')}`,
                  }}
                  variant="outlined"
                  className={classes.button}
                  onClick={() => Window.navigate('../review')}
                >
                  Get your resume reviewed
                </Button>
                <Button
                  startIcon={<Icon src="youtube" color="white" />}
                  style={{
                    color: Colors.get('white'),
                    backgroundColor: Colors.get('red'),
                  }}
                  className={classes.button}
                  onClick={() => Logger.log('YouTube', URLs.YouTube)}
                >
                  Subscribe to my channel
                </Button>
              </GridList>
            </Grid>
          </GridList>
        </Grid>

        {/** OTHER RESUMES */}
        <Divider className={classes.divider} />
        <Grid item className={classes.section}>
          See how I used this template to get into...
        </Grid>
        <Grid item className={classes.resumes}>
          <GridList cellHeight={'auto'} cols={100} className={classes.cards}>
            {_.map(resumes, (resume, idx) => (
              <Card
                {...resume}
                key={idx}
                className={classes.card}
                event="resume"
              />
            ))}
          </GridList>
        </Grid>

        {/** VIDEO + SUBSCRIBE CTA */}
        <Divider className={classes.divider} />
        <Grid item className={classes.cta}>
          <div>
            <LiteYouTubeEmbed id="1BLEnkNE_XU" />
          </div>
          <div>
            If you found this helpful, please consider{' '}
            <CustomLink
              text="Subscribing to my YouTube channel"
              event="YouTube"
              url={URLs.YouTube}
              color="red"
            />
            .
          </div>
          <div>I'm planning to produce more high-value content :)</div>
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(Resumes)
